




























































































































import { AnyObject } from '@movecloser/front-core'
import { Component, Mixins, Prop, Ref, Vue } from 'vue-property-decorator'

import { AllowedAttributes } from '../../../../../contexts'
import { BadgeProps } from '../../../../../dsl/atoms/Badge'

import { ShapeMap, SizeMap } from '../../../../../dsl/composables'

import { translateProductToVariantsSwitch, VariantsSwitch } from '../../../molecules/VariantsSwitch'
import { units } from '../../../config/units'

import { ProductCardConfig } from '../ProductCard.config'
import { Application } from '../../ProductHeader'
import ProductCardMixin from '../ProductCard.mixin'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<ProductCardDefault>({
  name: 'ProductCardDefault',
  components: { VariantsSwitch },
  mounted (): void {
    this.checkIsFavourite()
    this.calculateVariantSwitchPositionY()
  }
})
export class ProductCardDefault extends Mixins<ProductCardMixin>(ProductCardMixin) {
  @Prop({
    type: Object,
    required: true
  })
  public readonly configuration!: ProductCardConfig

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
  public readonly isCartAvailableForLocale!: boolean

  @Ref('variantSwitchRef')
  public variantSwitchRef!: Vue

  // FIXME!: By default only display color VariantSwitcher in ProductCard
  //         if changes will be required move this logic into config.
  public readonly DEFAULT_IDENTIFIER = 'color'

  public config: AnyObject = { ...this.configuration }

  public variantUnit: string = ''
  public variantVolume: number = 0
  public units: any = units
  public variantSwitchPositionY = '-90px'

  /**
   * Determines the application of current product
   */
  protected get applicationOptions (): string[] | null {
    const application: string | undefined = this.getAttribute<string>(AllowedAttributes.Application)

    if (typeof application === 'undefined') {
      return null
    }

    if (application.includes('/')) {
      return application.split('/')
    }

    return [application]
  }

  /**
   * Determines (constructs) variant badges.
   */
  public get badges (): BadgeProps[] {
    const badges: BadgeProps[] = []

    // if (!this.isFinalPriceDifferent )
    if (this.isSale) {
      badges.push({
        label: this.$t('front.products.organisms.productCard.attributes.isSale').toString(),
        theme: this.configuration.badgeThemes[AllowedAttributes.IsSale] ?? this.badgeTheme,
        shape: ShapeMap.Rectangle,
        variant: this.badgeVariant,
        size: SizeMap.Medium
      })
    } else {
      if (this.isFinalPriceDifferent) {
        badges.push({
          label: this.$t('front.products.organisms.productCard.attributes.isPromotion').toString(),
          theme: this.badgeTheme,
          shape: ShapeMap.Rectangle,
          variant: this.badgeVariant,
          size: SizeMap.Medium
        })
      }
    }

    if (this.isNew) {
      badges.push({
        label: this.$t('front.products.organisms.productCard.attributes.isNew').toString(),
        theme: this.configuration.badgeThemes[AllowedAttributes.IsNew] ?? this.themeOfNewBadge,
        shape: ShapeMap.Rectangle,
        variant: this.badgeVariant,
        size: SizeMap.Small
      })
    }
    if (this.isBestseller) {
      badges.push({
        label: this.$t('front.products.organisms.productCard.attributes.isBestseller').toString(),
        theme: this.configuration.badgeThemes[AllowedAttributes.IsBestseller] ?? 'flat',
        shape: ShapeMap.Rectangle,
        variant: this.badgeVariant,
        size: SizeMap.Small
      })
    }
    // }
    return badges
  }

  public get badgeVariant (): string {
    return this.getConfigProperty('badgeVariant')
  }

  public get badgeTheme (): string {
    return this.getConfigProperty('badgeTheme')
  }

  public get buttonTheme (): string {
    return this.getConfigProperty('buttonTheme')
  }

  public get displayDiscountAsSale (): boolean {
    return this.getConfigProperty('displayDiscountAsSale')
  }

  /**
   * Determines button variant.
   */
  public get buttonVariant (): string {
    return this.getConfigProperty('buttonVariant')
  }

  public get calculateVariantVolume () {
    if
    (this.activeVariant === undefined ||
        !this.activeVariant ||
        !this.activeVariant.attributes.size) {
      this.variantVolume = 1
      this.variantUnit = 'szt.'
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const capacity = this.activeVariant.attributes.size.split(' ')
      this.variantVolume = capacity[0]
      this.variantUnit = capacity[1]
    }

    if (this.units[this.variantUnit] === undefined) {
      return false
    }

    const u = this.units[this.variantUnit].volume
    const volume = (u * this.activeVariant!.price?.finalPrice ?? 0) / this.variantVolume

    // return Math.round(volume * 100) / 100
    return volume.toFixed(2)
  }

  public calculatePercents (value: number) {
    if (value === 0) {
      return value + '%'
    }

    if (value < 100 && value > 0) {
      return '-' + value + '%'
    }

    return '+' + Math.abs((value)) + '%'
  }

  public get calculateBasicPricePercents () {
    if (this.activeVariant?.price) {
      const calc = Math.round(100 - ((this.activeVariant!.price.finalPrice * 100) /
          this.activeVariant!.price.regularPrice))
      return this.calculatePercents(calc)
    }
  }

  public get calculateOmnibusPercents () {
    if (!this.activeVariant?.price.previousPrice) {
      return this.calculatePercents(0)
    }
    const calc = Math.round(100 - ((this.activeVariant?.price.finalPrice * 100) /
        this.activeVariant?.price.previousPrice))
    return this.calculatePercents(calc)
  }

  /**
   * Determines the whether final price is different.
   */
  public get isFinalPriceDifferent (): boolean {
    return this.hasDiscount
  }

  /**
   * Determines whether **VARIANT** is new.
   */
  private get isNew (): boolean {
    if (typeof this.getAttribute<boolean>(AllowedAttributes.IsNew) === 'undefined') {
      return false
    }

    return Boolean(this.getAttribute<boolean>(AllowedAttributes.IsNew))
  }

  private get isBestseller (): boolean {
    if (typeof this.getAttribute<boolean>(AllowedAttributes.IsBestseller) === 'undefined') {
      return false
    }

    return Boolean(this.getAttribute<boolean>(AllowedAttributes.IsBestseller))
  }

  /**
   * Determines whether **VARIANT** is on sale.
   //  */
  private get isSale (): boolean {
    if ((typeof this.getAttribute<boolean>(AllowedAttributes.IsSale) === 'undefined' ||
        !this.getAttribute<boolean>(AllowedAttributes.IsSale))) {
      if (this.displayDiscountAsSale && this.hasDiscount) {
        return true
      }
      return false
    }

    return true
  }

  /**
   * Determines whether product has more that one variant.
   */
  public get hasMoreVariants (): boolean {
    if (!this.product.variantSelector || !(this.DEFAULT_IDENTIFIER in this.product.variantSelector)) {
      return false
    }

    return Object.keys(this.product.variantSelector[this.DEFAULT_IDENTIFIER]).length > 1
  }

  public get themeOfNewBadge (): string {
    return this.getConfigProperty('themeOfNewBadge')
  }

  public calculateVariantSwitchPositionY () {
    const productCardVariantsSwitchEl = this.$refs.variantSwitchRef as Vue
    if (!productCardVariantsSwitchEl) {
      return
    }
    const productCardVariantsSwitchHeight = productCardVariantsSwitchEl.$el.getBoundingClientRect().height

    this.variantSwitchPositionY = `${-productCardVariantsSwitchHeight}px`
  }

  /**
   * Change content position on mouse over for products with variants.
   * @param htmlDivElement
   */
  public animateContentOnMouseOver (htmlDivElement?: HTMLDivElement) {
    if (!this.shouldAnimate) {
      return
    }

    const productCardVariantsSwitchEl = this.$refs.variantSwitchRef as Vue

    if (!htmlDivElement || !productCardVariantsSwitchEl || !productCardVariantsSwitchEl || !this.hasVariants) {
      return
    }

    const productCardVariantsSwitchHeight = productCardVariantsSwitchEl.$el.getBoundingClientRect().height

    htmlDivElement.style.transform = `translateY(${-productCardVariantsSwitchHeight}px)`
  }

  /**
   * Check content position on mouse leave for products with variants.
   * @param htmlDivElement
   */
  public animateContentOnMouseLeave (htmlDivElement?: HTMLDivElement) {
    if (!this.shouldAnimate) {
      return
    }

    if (!htmlDivElement || !this.hasVariants) {
      return
    }

    htmlDivElement.style.transform = 'translateY(0px)'
  }

  /**
   *
   * TODO: Should be operated by key, not by text.
   * Gets the application of the active variant.
   */
  public getApplication (application: string): undefined | string {
    const day = this.$t(`front.products.organisms.productHeader.attributes.application.${Application.day}`)
    const night = this.$t(`front.products.organisms.productHeader.attributes.application.${Application.night}`)
    const dayNight = this.$t(`front.products.organisms.productHeader.attributes.application.${Application['day/night']}`)
    switch (application) {
      case day:
        return 'DayIcon'
      case night:
        return 'NightIcon'
      case dayNight:
        return 'DayNightIcon'
    }
  }

  public get variantsSwitchProps () {
    return translateProductToVariantsSwitch(this.product, 'color')
  }
}

export default ProductCardDefault
